<template>
  <div class="wrapper">
    <p :style="{ color: TxtColor}" class="txt-number">{{TxtNumber}}</p>
    <div class="details-wrapper">
      <p class="txt-title">{{TxtTitle}}</p>
      <p class="txt-describle">{{TxtDescrible}}</p>
    </div>
    <!-- <img class="img-icon" src="@/assets/img/arrow-right.svg" alt="" srcset=""> -->
  </div>
</template>

<script>
export default {
  name: 'ProfileHeaderContent',
  data () {
    return {

    }
  },
  props: {
    TxtNumber: {
      type: Number,
      default: () => 0
    },
    TxtTitle: {
      type: String,
      default: () => 'áreas de actuação'
    },
    TxtDescrible: {
      type: String,
      default: () => 'por prestações de serviços feitas no rise'
    },
    TxtColor: {
      type: String,
      default: () => '#fc0'
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrapper{
    position: relative;
    display: flex;
    align-items: center;
    width: 400px;
    height: 200px;
    justify-content: center;
    z-index: 99!important;
    background: transparent;
    @media(max-width:768px){
      width: 100%;
    }
    img{
      margin-top: -22px;
    }
    .txt-number{
      color: #fff;
      font-family: "NexaLight",sans-serif;
      font-size: 90px;
      @media(max-width:768px){
        font-size: 65px!important;
        width: 50%;
      }
    }
    .details-wrapper{
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      padding: 10px 20px 0;
      @media(max-width:768px){
        width: 50%;
      }
      .txt-title{
        color: #fff;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1.5px;
      }
      .txt-describle{
        color: #FDB00B;
        font-size: 12px;
        max-width: 170px;
      }
      p{
        text-align: start;
      }
    }
  }
</style>
