<template>
  <div class="my-area">
    <my-area-parceiro/>
  </div>
</template>

<script>
import Parceiro from '@/components/MyArea/Parceiro'
export default {
  name: 'my-area',
  components: {
    'my-area-parceiro': Parceiro
  }
}
</script>

<style lang="scss" scoped>

</style>
