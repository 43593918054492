<template>
  <div class="profile">
      <header :class="[{'header-expand': headerExpand, 'header-short': headerShort}]">
        <div class="logo-container">
          <router-link to="/">
            <app-brand />
          </router-link>
        </div>
          <div class="counters-container">
            <profile-header-content
              :class="[{'header-children': headerChildren}, {'header-children2': true}]"
              txt-color="#fff"
              :txt-number="minhaAreaData.countProfissionaisVisualizados"
              txt-title="Visualizados"
              txt-describle="por prestações de serviços feitas no rise"
            />
            <div class="close-open" :class="{'header-children': headerChildren}">
              <button @click="toggleCloseOpen()">Ver tudo</button>
            </div>
             <profile-header-content
              :class="{'header-children-expand': headerChildrenExpand}"
              txt-color="#fff"
              :txt-number="minhaAreaData.countProfissionaisVisualizados"
              txt-title="Visualizados"
              txt-describle=""
            />
            <profile-header-content
              :class="{'header-children-expand': headerChildrenExpand}"
              txt-color="#FDB00B"
              :txt-number="minhaAreaData.countProfissionaisFavoritos"
              txt-title="favoritos"
              txt-describle=""
            />
            <profile-header-content
              :class="{'header-children-expand': headerChildrenExpand}"
              txt-color="#fff"
              :txt-number="minhaAreaData.countPedidosInformacao"
              txt-title="Pedidos submetidos"
              txt-describle=""
            />
            <profile-header-content
              :class="{'header-children-expand': headerChildrenExpand}"
              txt-color="#FDB00B"
              :txt-number="minhaAreaData.countPedidosInformacaoEstadoContratado"
              txt-title="Contratados"
              txt-describle=""
            />
             <div class="closeall" :class="{'closeall-toggle': showall}">
              <button @click="toggleClose()">Fechar</button>
            </div>
          </div>
          <section class="toobar-container">
            <toobar-search
              toobar-background="#2EA9AD"
              hide-user="flex"
              hide-user-bg="#2EA9AD"
              hide-home="flex"
              hide-home-bg="#2EBFAD"
              input-background="#6A4C7C"
              hide-input-search="none"
              :hide-user-icon="require('@/assets/img/logout-icon.png')"
            />
          </section>
      </header>
      <section class="user-info-container">
          <div class="user-desc-wrapper">
              <div class="data-wrapper m-r">
                <div class="data-wrapper-name">
                  <p class="empresa">{{minhaAreaData.nome}}</p>
                  <p class="nome">{{minhaAreaData.empresa}}</p>
                </div>
              </div>
               <div class="data-wrapper">
                <div>
                  <p class="title">e-mail</p>
                  <p class="info_empresa">{{minhaAreaData.email}}</p>
                </div>
                <div>
                  <p class="title">função</p>
                  <p class="info_empresa">{{minhaAreaData.funcao}}</p>
                </div>
                <div>
                  <p class="title">sector</p>
                  <p class="info_empresa">{{minhaAreaData.sector}}</p>
                </div>
              </div>
          </div>
      </section>
      <section class="user-goals-container">
          <div class="tittle-wrapper">
            <p>Profissionais Visualizados</p>
          </div>
          <base-container-wrapper-grid
          class="space-medium"
          :arraySlideInfo="minhaAreaData.profissionaisVisualizados"
          >
            <template #swiper-card="{ item }">
              <card-item
              :item="item"
              @handler-profissional="getProfissionalData"
              name-id="idProfissional"
              />
            </template>
            <template #modals>
              <modal-details
                :id-from-list="modalData"
                v-if="showModal"
                @close="showModal = false"
              />
            </template>
          </base-container-wrapper-grid>
      </section>
      <section class="user-goals-container-2">
        <div class="tittle-wrapper">
          <p>Favoritos</p>
        </div>
          <base-container-wrapper-grid
            class="space-medium"
            :arraySlideInfo="minhaAreaData.profissionaisFavoritos"
          >
          <template #swiper-card="{ item }">
            <card-item
            :item="item"
            @handler-profissional="getProfissionalData"
            />
          </template>
        </base-container-wrapper-grid>
      </section>
       <section class="user-goals-container">
          <div class="tittle-wrapper">
            <p>PEDIDOS SUBMETIDOS</p>
          </div>
           <base-container-wrapper-grid
           class="space-medium"
          :arraySlideInfo="minhaAreaData.pedidosInformacao"
          >
            <template #swiper-card="{ item }">
              <card-item
              :item="item"
              name-id="idProfissional"
              @handler-profissional="getProfissionalData"
              />
            </template>
          </base-container-wrapper-grid>
      </section>
       <sign-in-out-modal
        v-if="confirmModal"
        @close="confirmModal = false"
        :modal-go-home="goHome"
        :modal-title="modalText"
        :show-cancel-yes="showBtn"
        :show-cancel-btn="showBtn"
      />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ProfileHeaderContent from './ParceiroHeaderContent'
import SignInOutModal from '@/components shared/Modal/ConfirmModalSignOut'
import ToobarSearch from '@/components shared/ToobarSearch/toobar-search'
import BaseContainerWrapperGrid from '@/components/Home/base-container-wrapper-grid'
import CardItem from '@/components/Home/card'
import ModalDetails from '@/components shared/Modal/ProfissionalModalDetails'
import Brand from '@/components shared/brand-rise-work'
import { isAuthenticated, isAuthenticatedParceiro } from '@/utils/auth'
export default {
  name: 'Profile',
  computed: {
    ...mapGetters('minha_area', ['MinhaAreaInfo'])
  },
  methods: {
    toggleCloseOpen () {
      this.headerShort = false
      this.headerExpand = true
      this.headerChildren = true
      this.showall = true
      this.headerChildrenExpand = false
    },
    toggleClose () {
      this.headerShort = true
      this.headerExpand = false
      this.headerChildren = false
      this.showall = true
      this.headerChildrenExpand = true
    },
    getProfissionalData (id) {
      if (isAuthenticatedParceiro() && !isAuthenticated()) {
        this.showModal = true
        this.modalData = id
        // this.AddView(id)
      } else if (!isAuthenticatedParceiro() && !isAuthenticated()) {
        this.modalText = 'Para ver detalhes do Profissional deve iniciar sessão como Parceiro. Deseja continuar?'
        this.modalBtnContinue = true
        this.confirmModal = true
        localStorage.setItem('@Rise_Parceiro_Home_Recents', 'yes')
      } else if (isAuthenticated() && !isAuthenticatedParceiro()) {
        this.modalText = 'Para ver detalhes do Profissional deve ter sessão iniciada como Parceiro. Tem certeza que pretender terminar a sessão actual?'
        this.modalBtnContinue = true
        this.confirmModal = true
        this.beTrue = false
        localStorage.setItem('@Rise_Parceiro_Home_Recents', 'yes')
      }
    },
    ...mapActions('minha_area', ['getMinhaAreaInfo'])
  },
  created () {
    document.title = 'Rise | Minha área'
  },
  mounted () {
    const ScreenSize = window.screen.width
    if (ScreenSize > 768) {
      this.headerChildrenExpand = false
    }
    this.getMinhaAreaInfo('parceiros/minhaArea').then(resp => {
      if (resp.object === null) {
        this.confirmModal = true
      }
      let data = Object.assign({}, resp.object)
      data.profissionaisFavoritos = data.profissionaisFavoritos.map(item => {
        item.favorito = true
        return item
      })
      this.minhaAreaData = data
    })
  },
  components: {
    'profile-header-content': ProfileHeaderContent,
    'toobar-search': ToobarSearch,
    'sign-in-out-modal': SignInOutModal,
    'card-item': CardItem,
    'modal-details': ModalDetails,
    'base-container-wrapper-grid': BaseContainerWrapperGrid,
    'app-brand': Brand
  },
  data () {
    return {
      minhaAreaData: [],
      depoimentos: [],
      headerExpand: false,
      headerShort: true,
      showModal: false,
      showall: false,
      modalData: '',
      verifyAuth: false,
      confirmModal: false,
      beTrue: true,
      modalBtnContinue: false,
      goHome: true,
      showBtn: false,
      modalText: 'Sem informações disponíveis na minha área de parceiro.',
      headerChildren: false,
      headerChildrenExpand: true,
      Options1: {
        pagination: {
          el: '.pagination-testimony',
          clickable: true
        },
        navigation: {
          nextEl: '.controller-right',
          prevEl: '.controller-left'
        },
        slidesPerView: 2,
        direction: 'horizontal',
        spaceBetween: 4,
        updateOnWindowResize: false,
        mousewheel: false,
        draggable: false,
        keyboard: true,
        centeredSlides: false
      },
      Options2: {
        pagination: {
          el: '.pagination-testimony',
          clickable: true
        },
        navigation: {
          nextEl: '.controller-right2',
          prevEl: '.controller-left2'
        },
        slidesPerView: 2,
        direction: 'horizontal',
        spaceBetween: 4,
        updateOnWindowResize: false,
        mousewheel: false,
        draggable: false,
        keyboard: true,
        centeredSlides: false
      },
      Options3: {
        pagination: {
          el: '.pagination-testimony',
          clickable: true
        },
        navigation: {
          nextEl: '.controller-right3',
          prevEl: '.controller-left3'
        },
        slidesPerView: 2,
        direction: 'horizontal',
        spaceBetween: 4,
        updateOnWindowResize: false,
        mousewheel: false,
        draggable: false,
        keyboard: true,
        centeredSlides: false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.counters-container {
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
  @media(max-width:768px){
    flex-direction: column;
  }
}
  .counters {
    font-family: "NexaLight",sans-serif;
    width: 100%;
    height: 100px;
    overflow: hidden;
    @media screen and (min-width: 768px){
      min-height: 100vh
    }
    .list-counter {
      list-style: none;
      padding-left: 0;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
      }
      color: #fff;
      .description {
        display: flex;
        max-width: 320px;
        &:nth-child(even) {
          .number {
            color: rgb(253, 176, 11);
          }
        }
        div {
          margin-top: 45px;
        }
        .title {
          font-weight: normal;
          text-transform: uppercase;
          font-size: 14px;
          letter-spacing: 1.5px;
        }
        .info {
          font-size: 12px;
        }
        .number {
          font-family: "NexaLight",sans-serif;
          font-size: 80px;
          padding: 0;
          margin-right: 5px;
        }
      }
    }
  }
  .sem_pro{
    position: absolute;
    right: 20%;
    height: 411px;
    width: 400px;
     @media(max-width:768px){
      right: 0!important;
      top: 210px;
      width:100%!important;
      h1{
        font-size: 20px!important;
      }
    }
    h1{
      text-align: center;
      font-size: 20px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media(min-width:768px ){
    .closeall,
    .close-open{
      display: none;
    }
    .logo-container{
      display: none;
    }
  }
  @media(max-width: 768px){
    .closeall{
      top: unset!important;
      bottom: 85px!important;
      z-index: 99999999!important;
      display: none!important;
    }
    .closeall.closeall-toggle{
     @media(max-width:768px){
        display: flex;
        z-index: 999999999!important;
        justify-content: flex-end;
        width: 100%;
        position: relative;
        button{
          position: absolute!important;
          right: 00px!important;
          top: 50px;
        }
     }
    }
    .closeall,
    .close-open{
      width: 100%;
      position: absolute;
      top: 200px;
      display: flex;
      justify-content: flex-end;
      right: 20px;
      button{
        color: #FDB00B;
        font-weight: bolder;
        text-align: right;
        font-size: 16px;
        border: none;
        background: transparent;
      }
    }
    .logo-container{
      width: 70%;
      display: flex;
      justify-content: flex-start;
      // margin-bottom: -40px;
      position: absolute;
      left: 20px;
      top: 20px;
    }
    .profile-photo{
      margin-top: -140px;
    }
  }
  .logo-img{
    width:140px;
  }
  .header-children-expand{
    display: none;
  }
  .closeall-toggle{
    display: unset!important;
  }
  .header-children2{
    @media(min-width: 768px){
    //  padding-top: 125px;
    display: none;
   }
  }
  .header-children{
   display: none;
  }
  .profile{
    width: 100%;
    min-height: 100vh;
    height: auto;
    display: flex;
    flex-direction: column;
    @media(max-width:768px){
      height: auto!important;
    }
  }
  @media(max-width: 768px){
    .header-short{
      height: 300px;
    }
    .header-expand{
      height: 100vh!important;
    }
  }
  header{
    height: 350px;
    width: 100%;
    background:#107578;
    padding: 0 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    @media(max-width:768px){
      padding: 0;
      flex-direction: column;
      height: auto;
      align-items: center;
      .wrapper{
        margin: 0 auto;
        height: 150px;
        padding: 0 40px;
      }
    }
  }
  .user-info-container{
    height: 100px;
    width: 100%;
    display: flex;
    padding: 0 5%;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    @media(max-width: 768px){
      flex-direction: column;
      height: auto!important;
    }
    .user-desc-wrapper{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto;
      width: 100%;
      // border: solid 2px springgreen;
      @media(min-width:768px){
         align-items: flex-start;
         padding: 0 5%;
         justify-content: flex-start;
      }
      @media(max-width:768px){
        flex-direction: column;
        height: auto;
        flex: unset !important;
        width: 100%;
        padding-top: 50px;
      }
      .profile-photo{
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        img{
          width: 150px;
          height: 150px;
          border-radius: 50%;
          object-fit: cover;
          border: 3px solid #0F7276;
        }
        .shodow{
          width: 140px;
          height: 140px;
          margin-top: -120px;
          background: #707070 0% 0% no-repeat padding-box;
          opacity: 0.1;
          filter: blur(22px);
        }
      }
      .data-wrapper{
        margin:0;
        display: flex;
        height: auto;
        width: auto;
        justify-content: flex-start;
        .data-wrapper-name{
          width: auto;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin: 0 50px 0 0!important;
          p{
            padding: 0;
           width: 100%;
          }
        }
        div{
          margin: 0 20px;
        }
        @media(max-width:768px){
          margin: 20px 0;
          flex-direction: column;
          .nome{
            font-size: 22px!important;
            padding: 4px 0px;
            color: #177D81!important;
            font: Light 26px/34px Roboto;
          }
          .empresa{
            font-size: 30px!important;
            padding: 4px 0;
          }
          .info_empresa{
            padding: 4px 0;
            color: #177D81!important;
            font-weight: unset!important;
          }
          .title{
            color: #9D9D9D!important;
            letter-spacing: 1.8px!important;
          }
          .info_empresa,
          .info_user,
          .title{
            font-size: 18px!important;
          }
        }
        .main-title{
          text-transform: uppercase;
          text-align: start;
          font-size: 22px;
          color: #868686;
        }
        .title{
          text-transform: uppercase;
          text-align: start;
          font-size: 14px;
          color: #868686;
        }
        .nome,
        .info_user {
          text-transform: capitalize;
          text-align: start;
          font-size: 13px;
          padding: 2px;
          color: #868686;
        }
        .info_empresa{
           text-transform: capitalize;
          text-align: start;
          font-size: 13px;
          padding: 10px 0px;
          color: #177D81;
          font-weight: bold;
        }
        .empresa{
          text-transform: capitalize;
          text-align: start;
          font-weight: bold;
          font-size: 25px;
          color: #177D81;
          padding: 10px 0;
        }
      }
    }
    .user-occupation-area{
      flex: 1;
      display:flex;
      flex-direction: column;
      align-items: center;
      @media(max-width:768px){
        width: 100%;
        padding-bottom: 30px;
      }
      .title{
        color: #868686;
        text-transform: uppercase;
        letter-spacing: 1.7px;
      }
      .desc{
        color: #868686;
        max-width: 230px ;
        font-size: 14px;
        text-align: start;
        @media(max-width: 768px){
          max-width: 90%!important;
          font-size: 16px;
        }
      }
      .buttons-container{
        display: flex;
        align-items: center;
        button{
          box-shadow: 0px 3px 10px;
          background: #0F7276;
          border: none;
          color: #fff;
          font-size: 18px;
          font-family: "NexaLight",sans-serif;
          padding: 12px 50px;
          border-radius: 34px;
          margin-left: 50px;
          cursor: pointer;
        }
        select {
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        appearance: none;
        outline: 0;
        box-shadow: none;
        border: 0 !important;
        background: #fff;
        background-image: none;
      }

      select::-ms-expand {
        display: none;
      }
      .select {
        margin: 25px 0;
        position: relative;
        display: flex;
        width: 13em;
        height: 3em;
        line-height: 3;
        background: #fff;
        overflow: hidden;
        border-radius: 36px;
        -webkit-box-shadow: 0px 0px 19px -9px rgba(0,0,0,0.16);
        -moz-box-shadow: 0px 0px 19px -9px rgba(0,0,0,0.16);
        box-shadow: 0px 0px 19px -9px rgba(0,0,0,0.16);
      }
      select {
        flex: 1;
        padding: 0 .5em;
        color: #000;
        font-family: "NexaLight",sans-serif;
        padding-left: 20px;
        font-size: 16px;
        cursor: pointer;
      }

      .select::after {
        content: '<';
        position: absolute;
        font-weight: 900;
        font-size: 20px;
        transform: rotate(-90deg);
        font-family: 'Advent Pro', sans-serif!important;
        top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        right: 12px;
        border-radius: 50%;
        padding: 0;
        background: #fff;
        -webkit-box-shadow: 0px 2px 19px -9px rgba(0,0,0,0.52);
        -moz-box-shadow: 0px 2px 19px -9px rgba(0,0,0,0.52);
        box-shadow: 0px 2px 19px -9px rgba(0,0,0,0.52);
        cursor: pointer;
        pointer-events: none;
        -webkit-transition: .25s all ease;
        -o-transition: .25s all ease;
        transition: .25s all ease;
      }

      .select:hover::after {
        color: #f39c12;
      }
      }
    }
  }
  .user-goals-container-2{
    background: #fff!important;
  }
  .user-goals-container-2,
  .user-goals-container{
    padding: 20px;
    width: 100%;
    background: #EFEFEF;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    // padding-bottom: 70px;
    flex-direction: column;
    .tittle-wrapper{
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: flex-start;
      @media(max-width:768px){
        height: auto!important;
        p{
          text-align: start!important;
        }
      }
      p{
        text-transform: uppercase;
        font-size: 25px;
        font-family: "Roboto";
        color: #9D9D9D;
      }
    }
    .card-container{
      display: flex;
      width: 100%;
      padding: 0 20px;
    }
    .card-wrapper{
      display: flex;
      width: 400px!important;
      margin-right: 25px!important;
      height: 140px;
      background: #fff;
      box-shadow: 0px 3px 10px #00000029;
      border-radius: 16px;
      align-items: center;
      padding: 0 20px;
      box-sizing: border-box;
      position: relative;
      .wrapper-picture{
        width: 90px;
        height: 86px;
        border-radius: 50%;
        @media(max-width:768px){
          width: 90px!important;
          height: 86px!important;
        }
        img{
          width: 90px;
          height: 86px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .photo-wrapper{
        width: 90px;
        height: 86px;
        .photo{
          background: #FDB00B;
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
        .rise-top{
          position: absolute;
          width: 30px;
          height: auto;
          left: 85px;
          top: 80px;
        }
      }
      .info-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 20px;
        box-sizing: border-box;
      }
      .state-wrapper{
          width: fit-content;
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
          box-sizing: border-box;
          border-radius: 32px;
          // background: #03C43E;
          margin-bottom: 5px;
          p{
            color: #fff;
            font-family: "Roboto";
            font-size: 12px;
          }
        }
        .description-wrapper{
          max-width: 240px;
          .desc{
            color: #9D9D9D;
            font-size: 14px;
            text-align: left;
          }
        }
        .location-and-lang-wrapper{
          display: flex;
          width: 100%;
          justify-content: space-between;
          padding: 10px 0 0 0;
          .location{
            font-size:12px;
            color: #9D9D9D;
          }
          .lang{
            padding: 2px 5px;
            background: #EFEFEF;
            color: #505050;
            font-size: 12px;
            border-radius: 34px;
          }
        }
    }
    @media(max-width: 768px){
      // display: none;
      /*
      padding-left: 4%!important;
      */
    }
    .my-goals-wrapper{
      flex: 1;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      .my-goals-title{
        text-transform: uppercase;
        color: #342041;
        font-size: 20px;
        font-family: "NexaLight",sans-serif;
      }
    }
    .desc-container{
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 70%;
      p{
        color: #868686;
        font-size: 16px;
        text-align: start;
      }
    }
  }
  .toobar-container{
    position: relative;
    bottom: 0;
    width: 100%;
    height: auto;
    // padding: 0 10%;
    @media(max-width:768px){
      padding: 0;
      position: relative;
    }
  }
  .slides-container{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .slides{
    display: flex;
    height: auto;
    align-items: center;
    justify-content: flex-start;
    width:100%;
    padding:10px 0 10px 20px;
    overflow: hidden;
    width:100%;
    @media(max-width:768px){
      padding-top: 50px;
      height: auto!important;
      // padding-bottom: 100px!important;
    }
  }
  .slide{
    width:508px!important;
    height: 201px;
    background: #fff;
    border-radius: 14px;
    -webkit-box-shadow: 0px 0px 21px 1px rgba(0,0,0,0.18);
    -moz-box-shadow: 0px 0px 21px 1px rgba(0,0,0,0.18);
    box-shadow: 0px 0px 21px 1px rgba(0,0,0,0.18);
    display: flex;
    flex-direction: column;
    padding: 15px;
    box-sizing: border-box;
    margin: 41px 25px 0;
    @media(max-width: 768px){
      width: 75vw!important;
      height: 331px!important;
      padding: 15px 0;
    }
    @media(max-width:320px){
      height: 310px!important;
    }
  }
  .swiper-wrapper{
    display: flex;
    align-items: center!important;
  }
  .slide-wrapper{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    overflow: hidden;
  }
   .slide-controllers{
      display: flex;
      width: 100%;
      @media(max-width:768px){
        display: none;
      }
  }
   .slide-controllers-wrapper{
      margin-top:60px;
      margin-left: -5px;
      height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 40px;
      z-index: 999;
      justify-content: center;
      position: absolute;
      @media(max-width:768px){
        display: none;
      }
      .swiper-button-prev:after{
        font-size: 10px!important;
      }
     .swiper-button-prev:after, .swiper-button-next:after {
        font-family: swiper-icons;
        font-size: 13px;
        font-weight: bolder;
        text-transform: none !important;
        letter-spacing: 0;
        color: #000!important;
        text-transform: none;
        font-variant: initial;
      }
      .controller-right3,
      .controller-right2,
     .controller-right{
        top: 32px;
        position: absolute;
        width: 28px;
        left: 12px;
        height: 28px;
        background: #fff;
        border-radius: 50%;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-box-shadow: 0px 5px 26px -10px rgba(0,0,0,0.6);
        -moz-box-shadow: 0px 5px 26px -10px rgba(0,0,0,0.6);
        box-shadow: 0px 5px 26px -10px rgba(0,0,0,0.6);
        cursor: pointer;
        img{
          width: 15px;
          height: 15px;
        }
    }
     .controller-left3,
    .controller-left2,
    .controller-left{
       width: 18px;
       top: 66px;
        height: 18px;
        left: 14px;
        position: absolute;
        background: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-box-shadow: 0px 5px 26px -10px rgba(0,0,0,0.6);
        -moz-box-shadow: 0px 5px 26px -10px rgba(0,0,0,0.6);
        box-shadow: 0px 5px 26px -10px rgba(0,0,0,0.6);
        cursor: pointer;
        img{
          width: 10px;
          height: 10px;
        }
    }
  }
</style>
